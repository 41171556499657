import React, {useEffect} from "react";
import {graphql, Link, StaticQuery} from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Sticky from "react-stickynode";

const useScript = url => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = url
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

function Sidebar() {
  useScript("//cdn.carbonads.com/carbon.js?serve=CE7IEKQU&placement=michalzaleckicom");

  return (
    <Wrapper>
      <Widget>
        <WidgetTitle>Popular</WidgetTitle>
        <LinksList>
          <LinksListItem><LinksListLink to="/category/javascript">JavaScript</LinksListLink></LinksListItem>
          <LinksListItem><LinksListLink to="/category/typescript">TypeScript</LinksListLink></LinksListItem>
          <LinksListItem><LinksListLink to="/category/blockchain">Blockchain</LinksListLink></LinksListItem>
          <LinksListItem><LinksListLink to="/category/testing">Testing</LinksListLink></LinksListItem>
          <LinksListItem><LinksListLink to="/category/node-js">Node.js</LinksListLink></LinksListItem>
          <LinksListItem><LinksListLink to="/category/react">React</LinksListLink></LinksListItem>
          <LinksListItem><LinksListLink to="/category/docker">Docker</LinksListLink></LinksListItem>
        </LinksList>
      </Widget>
      <StaticQuery
        query={graphql`
          query {
            jestBookCover: file(relativePath: { eq: "jest-book-cover.png" }) {
              childImageSharp {
                fluid(maxWidth: 168, maxHeight: 220, quality: 85) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        `}
        render={(data) => (

          <Widget>
            <WidgetTitle>Ebook</WidgetTitle>
            <Ebook href="/ebooks/mastering-jest-tips-tricks-for-javascript-developers.html">
              <EbookCover fadeIn={false} critical={true} fluid={data.jestBookCover.childImageSharp.fluid}/>
              <EbookTitle>Mastering Jest:<br/>Tips &amp; Tricks | $9</EbookTitle>
            </Ebook>
          </Widget>

        )}
      />
      <Sticky top={40} innerZ={99}>
        <Widget>
          <CarbonWrapper dangerouslySetInnerHTML={{
            __html: `
                      <script async type="text/javascript" src="//cdn.carbonads.com/carbon.js?serve=CE7IEKQU&placement=michalzaleckicom" id="_carbonads_js"></script>
                    `
          }}>
          </CarbonWrapper>
        </Widget>
      </Sticky>
    </Wrapper>
  );
}

const CarbonWrapper = styled.div`
   #carbonads {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
    Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  #carbonads {
    display: flex;
    max-width: 330px;
    background-color: hsl(0, 0%, 98%);
    box-shadow: 0 1px 4px 1px hsla(0, 0%, 0%, .1);
  }

  #carbonads a {
    color: inherit;
    text-decoration: none;

    /* overwrite */
    background-image: none;
  }

  #carbonads a:hover {
    color: inherit;
  }

  #carbonads span {
    position: relative;
    display: block;
    overflow: hidden;
  }

  #carbonads .carbon-wrap {
    display: flex;

    /* overwrite */
    flex-direction: column;
  }

  .carbon-img {
    display: block;
    margin: 0;
    line-height: 1;
  }

  .carbon-img img {
    display: block;

    /* overwrite */
    margin-bottom: 0;
    margin: 0 auto;
  }

  .carbon-text {
    font-size: 13px;
    padding: 10px;
    line-height: 1.5;
    text-align: left;
  }

  .carbon-poweredby {
    display: block;
    padding: 8px 10px;
    background: repeating-linear-gradient(-45deg, transparent, transparent 5px, hsla(0, 0%, 0%, .025) 5px, hsla(0, 0%, 0%, .025) 10px) hsla(203, 11%, 95%, .4);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 600;
    font-size: 9px;
    line-height: 1;
  }
`;

const Wrapper = styled.aside`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Widget = styled.section`
  margin: 41px 0 0 40px;
  padding: 0 0 0 30px;
  border-left: 1px solid #B9B9B9;
`;

const WidgetTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const LinksList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
`;

const LinksListItem = styled.li`
  margin: 0;
  text-shadow: none;
  background-image: none;
  font-family: ${props => props.theme.headerFontFamily};
`;

const LinksListLink = styled(Link)`
  text-shadow: none;
  background-image: none;
  color: #000;
  font-size: 20px;
  line-height: 32px;
`;

const Ebook = styled.a`
  margin: 20px 0 0;
  display: block;
  text-shadow: none;
  background-image: none;
  color: #000;
`;

const EbookCover = styled(Img)`
  img {
    margin: 0;
  }
`;

const EbookTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-family: ${props => props.theme.headerFontFamily};
`;

export default Sidebar;
