import React from "react";
import styled from "styled-components";

class Cheetsheet extends React.Component {
  state = {
    hidden: false,
  };

  hide() {
    document.querySelectorAll("main p").forEach(el => {
      const next = el.nextElementSibling;
      if (!next || next.getAttribute("class") !== "gatsby-highlight") {
        el.setAttribute("hidden", true);
      }
    });
  };

  show() {
    document.querySelectorAll("main p[hidden=\"true\"]").forEach(el => {
      el.removeAttribute("hidden");
    });
  };

  handleClick = () => {
    if (this.state.hidden) {
      this.show();
    } else {
      this.hide();
    }
    this.setState(({ hidden }) => ({ hidden: !hidden }));
  };

  render() {
    const { hidden } = this.state;

    return (
      <Wrapper>
        <Button type="button" onClick={this.handleClick}>Cheetsheet mode: {hidden ? "ON" : "OFF"}</Button>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  margin-bottom: 1.58rem;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  border: 1px solid #B9B9B9;
  background: none;
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 16px;
  border-radius: 4px;
  padding: 3px 10px;
`;

export default Cheetsheet;
