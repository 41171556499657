import React from "react";
import styled from "styled-components";

export function FollowOnTwitter() {
  return (
    <Wrapper>
      <Paragraph>
        <strong>Did you enjoy it?</strong> Follow me{" "}
        <a href="https://twitter.com/MichalZalecki" target="_blank" rel="noreferrer noopener">@MichalZalecki on Twitter</a>,
        where I&nbsp;share some interesting, bite-sized content.
      </Paragraph>
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  border-top: 1px solid #B9B9B9;
  border-bottom: 1px solid #B9B9B9;
  margin-top: 1.58rem;
  margin-bottom: 3.16em;
  padding-top: 1.58rem;
`;

const Paragraph = styled.p`
  text-align: center !important;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;
