import React from "react";
import styled from "styled-components";

function Table({ children }) {
  return (
    <TableWrapper>
      <TableContent>{children}</TableContent>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  overflow-x: auto;
  margin-bottom: 1.58rem;
`;

const TableContent = styled.table`
  margin: 0;
  min-width: 500px;

  th, td {
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 80%;
    line-height: 1.5;
    padding: 5px 10px;
  }

  th {
    white-space: nowrap;
  }
`;

export default Table;
