import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import rehypeReact from "rehype-react";
import App from "../components/App";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import MetaText from "../components/MetaText";
import SEO from "../components/SEO";
import Ebook from "../components/Ebook";
import Table from "../components/Table";
import Cheetsheet from "../components/Cheetsheet";
// consider Hyvor Talk
// import { DiscussionEmbed } from "../components/Disqus";
import { Frame } from "../components/Frame";
import CategoriesList from "../components/CategoriesList";
import { formatDate } from "../utils/formats";
import { postPath } from "../utils/paths";
import { withSlug } from "../utils/withSlug";
import { FollowOnTwitter } from "../components/FollowOnTwitter";

function formatPostDate(dateString, updateDateString) {
  const maybeUpdatedString = updateDateString ? ` (updated ${formatDate(updateDateString)})` : "";
  return `${formatDate(dateString)}${maybeUpdatedString}`;
}

export default function Post(props) {
  const { markdownRemark: post } = props.data;
  const { hero } = post.frontmatter;

  const author = "Michal Zalecki";
  const categories = post.frontmatter.categories.split(" ").map(withSlug);

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { ebook: Ebook, table: Table, cheetsheet: Cheetsheet, twitter: FollowOnTwitter }
  }).Compiler;

  function getDateString() {
    if (post.frontmatter.digitalGarden) return '';
    return `on ${formatPostDate(post.frontmatter.date, post.frontmatter.update)}`
  }

  return (
    <App>
      <SEO post={post} />
      <Header />
      <main>
        <Article>
          <Frame>
            <h1>{post.frontmatter.title}</h1>
            <ArticleMeta>
              {author} {getDateString()}{" "}
              in <CategoriesList categories={categories} />
            </ArticleMeta>
          </Frame>
          {hero && <Hero fluid={hero.childImageSharp.fluid} />}
          <ArticleBody>
            <ArticleContent>
              {renderAst(post.htmlAst)}
              {/* <DiscussionEmbed path={postPath(post.fields.slug)} /> */}
            </ArticleContent>
            <Sidebar />
          </ArticleBody>
        </Article>
      </main>
      <Footer />
    </App>
  );
}

const Article = styled.article`
  padding: 26px 0 50px;
`;

const Hero = styled(Img)`
  img {
    margin: 0;
  }
`;

const ArticleMeta = styled(MetaText)`
  margin-bottom: 30px;
`;

const ArticleBody = styled.div`
  max-width: ${props => props.theme.contentWidth}px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 17fr 5fr 2fr;
  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

const ArticleContent = styled.div`
  padding: 41px 0 0;

  & > div {
    display: grid;
    grid-template-columns: repeat(17, 1fr);

    & > * {
      grid-column: 3 / 17;
    }

    .gatsby-highlight,
    .ytplayer {
      grid-column: 2 / 18;
    }

    .gatsby-highlight {
      max-width: 100%;
      margin-bottom: 22px;

      code {
        white-space: pre-wrap;
      }
    }
  }

  p {
    text-align: justify;
  }

  .gatsby-highlight-code-line {
    background: #FFFBDD;
    display: block;
    margin-left: -1em;
    margin-right: -1em;
    padding-right: 1em;
    padding-left: 1em;
  }

  @media (max-width: 768px) {
    & > div {
      grid-template-columns: repeat(17, 1fr);

      & > * {
        grid-column: 2 / 17;
      }

      .gatsby-highlight,
      .ytplayer {
        grid-column: 2 / 17;
      }
    }
  }

  @media (max-width: 736px) {
    & > *,
    .gatsby-highlight
    .ytplayer {
      grid-column: 2 / 24;
    }
  }
`;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        date
        update
        categories
        canonical
        description
        digitalGarden: digital_garden
        hero {
          childImageSharp {
            fluid (maxWidth: 1200, maxHeight: 600, quality: 85) {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
            og: fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
      htmlAst
    }
  }
`;
