import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

const JEST = "jest";
const ETHEREUM_SOLIDITY = "ethereumSolidity";

function EbookTemplate(props) {
  const { title, description, href, which } = props;

  return (
    <Wrapper>
      <Deco which={which}>EBOOK</Deco>
      <Columns>
        <LeftColumn>
          <Title>
            <TitleLink href={href}>
              {title}
            </TitleLink>
          </Title>
          <Desc>{description}</Desc>
          <Button which={which} href={href}>Get it now!</Button>
        </LeftColumn>
        <RightColumn>
          <StaticQuery
            query={graphql`
              {
                jest: file(relativePath: { eq: "jest-book-cover.png" }) {
                  childImageSharp {
                    fluid(maxWidth: 210, quality: 85) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                ethereumSolidity: file(relativePath: { eq: "ethereum-solidity-book-cover.png" }) {
                  childImageSharp {
                    fluid(maxWidth: 210, quality: 85) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            `}
            render={data => (
              <Cover fadeIn={false} critical={true} fluid={data[which].childImageSharp.fluid} alt="Ebook cover" />
            )}
          />
        </RightColumn>
      </Columns>
    </Wrapper>
  );
}

function Ebook(props) {
  const { which } = props;

  if (which === JEST) {
    return (
      <EbookTemplate
        which={JEST}
        title={<>Mastering Jest: Tips &amp;&nbsp;Tricks for JavaScript Developers</>}
        description={"This ebook goes beyond Jest documentation to explain software testing techniques. I focus on unit test separation, mocking, matchers, patterns, and best practices."}
        href="/ebooks/mastering-jest-tips-tricks-for-javascript-developers.html"
      />
    );
  }

  if (which === ETHEREUM_SOLIDITY) {
    return (
      <EbookTemplate
        which={ETHEREUM_SOLIDITY}
        title={<>Ethereum: 30 Tips &amp;&nbsp;Tricks For Solidity Developers</>}
        description={"This ebook is a set of bite-size examples with code listings that explain Solidity pitfalls, gives you security hints, and teach you helpful coding techniques."}
        href="/ebooks/ethereum-30-tips-tricks-for-solidity-developers.html"
      />
    );
  }

  throw new Error(`Ebook not found: ${which}`);
}

const Wrapper = styled.aside`
  margin-top: 0.57rem; /* 10px */
`;

const Deco = styled.div`
  font-weight: bold;
  font-size: 1.05rem; /* 20px */
  font-family: ${props => props.theme.headerFontFamily};
  letter-spacing: 0.1rem;
  margin: 0 0 1.05rem; /* 20px */

  ${props => props.which === JEST && `color: #DC605D;`}
  ${props => props.which === ETHEREUM_SOLIDITY && `color: #5748c5;`}
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: auto 30%;

  @media (max-width: 680px) {
    grid-template-columns: auto;
  }
`;

const LeftColumn = styled.div``;

const RightColumn = styled.div`
  @media (max-width: 680px) {
    grid-row: 1;
  }
`;

const Title = styled.h1`
  font-size: 1.73rem; /* 33px */
  margin: 0 0 1.05rem; /* 20px */
`;

const TitleLink = styled.a`
  text-decoration: none;
  color: inherit;
  text-shadow: none;
  background-image: none;
`;

const Desc = styled.p`
  text-align: left;
  margin: 0 0 1.05rem; /* 20px */
`;

const Button = styled.a`
  text-shadow: none;
  background-image: none;
  display: inline-block;
  padding: 7px 40px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 0.79rem; /* 15px */
  font-family: ${props => props.theme.headerFontFamily};

  &,
  &:hover {
    color: #FFF;
    text-decoration: none;
  }

  ${props => props.which === JEST && `
    background-color: #DC605D;
    &:hover {
      background-color: #ca5855;
    }
  `}

  ${props => props.which === ETHEREUM_SOLIDITY && `
    background-color: #5748c5;
    &:hover {
      background-color: #4e41b1;
    }
  `}
`;

const Cover = styled(Img)`
  img {
    margin: 0;
  }

  @media (max-width: 680px) {
    max-width: 320px;
    margin: 0 0 1.05rem; /* 20px */
  }
`;

export default Ebook;
